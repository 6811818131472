import React, {Component} from "react"
import Button from "@material-ui/core/Button"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ArrowForwardIcon from "@material-ui/icons/Forward"
import ArrowForwardThinIcon from "@material-ui/icons/ArrowForward"
import CommaIcons from '@material-ui/icons/FormatQuote';
import {isValidContentfulImage, isValidSectionField} from "../common/utils/CommonUtils";
import {graphql} from 'gatsby';
import "./home-page.scss"
import "./learning-library.scss"
import BreadCrumbs from "../components/bread-crumbs";

const breadCrumbsList  = [

    {
        title: "Home",
        link: "/",
    }

]

class CommunityPayment extends Component {

    constructor(props) {

        super(props);
        this.state = {
            openModal: false,

            educationalContentItems: [],
            filteredEducationalItems: [],

            loader: {
                options: {
                    position: 'absolute'
                }
            }

        };

        this.pageData = this.getFirstContentfulNode(this.props,
            'allContentfulCommunityPaymentModel');
    }

    getFirstContentfulNode = (props, key) => {
        return props.data[key].edges[0].node;
    };

    componentDidMount() {
        window.analytics.page("CommunityPayment");
    }

    render() {
        const {abovefooter, belowTopSection, shortsections, testimonials, topsection} = this.pageData;

        return (
            <Layout location={this.props.location}>

                <SEO title="Community Payment"/>
                <BreadCrumbs currentPage={"Community Payment"} breadCrumbsList={breadCrumbsList}/>
                <div className="get-started-home-wrapper">
                    <div className="get-started-home-inner">
                        <div className="get-started-text-wrap">
                            <img className="get-started-home-pattern"
                                 src={require(
                                     '../assets/images/home-bg-circles.png')}
                                 alt="Pattern"/>
                            {
                                isValidSectionField(topsection, "title") &&
                                <h1 className="blackHeading2">{topsection.title}</h1>
                            }
                            {
                                isValidSectionField(topsection, "description")
                                &&
                                <p className="parah"
                                   dangerouslySetInnerHTML={{__html: topsection.description.description}}></p>
                            }
                            <Button onClick={() => {

                            }}
                                    href={"/get-started"}
                                    className="blue-arrow-btn" color="primary">
                                Get Started <ArrowForwardIcon/>
                            </Button>
                        </div>
                        <div className="get-started-anim-wrap">
                            {
                                isValidContentfulImage(topsection.imageOrVideo)
                                && (
                                    <img className="get-started-home-hero-img"
                                         src={topsection.imageOrVideo?.file?.url}
                                         alt="providerImg"/>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className="new-home-manifesto-wrapper">
                    <div className="new-home-manifesto-inner">
                    </div>
                    <h2 className="new-community-works-title">{belowTopSection.title}</h2>
                    <p className="new-community-works-parah" dangerouslySetInnerHTML={{__html:belowTopSection.description.description}}></p>
                </div>
                <div className="new-home-features">
                    <div className="new-home-features-inner">

                        {
                            shortsections && shortsections.map(
                                (section, index) => (
                                    <div key={"key-" + section.heading}
                                         className="new-home-single-feature">
                                        {
                                            (index === 0 || index % 2 === 0)
                                            && (
                                                <div
                                                    className="new-feature-img-side">
                                                    <img
                                                        className="new-feature-image"
                                                        src={section.image.file.url}
                                                        alt=" Frame"/>
                                                </div>
                                            )
                                        }
                                        <div className="new-feature-text-side">
                                            {
                                                isValidSectionField(section,
                                                    "heading") &&

                                                <h2 className="feature-heading">
                                                    {section.heading}
                                                </h2>
                                            }

                                            {

                                                isValidSectionField(section,
                                                    "description") &&

                                                <p className="feature-para"
                                                   dangerouslySetInnerHTML={{__html: section.description.description}}></p>
                                            }
                                        </div>
                                        {
                                            index % 2 !== 0 && (
                                                <div
                                                    className="new-feature-img-side"
                                                    style={{textAlign: "right"}}>
                                                    {isValidContentfulImage(
                                                        section.image) && (
                                                        <img
                                                            className="new-feature-image"
                                                            src={section.image.file.url}
                                                            alt=" Frame"/>
                                                    )}

                                                </div>
                                            )
                                        }
                                    </div>
                                ))
                        }
                    </div>
                </div>



                <div className="new-testimonial-wrapper">
                    <div className="new-testimonial-inner">
                        <div className="testi-heading-section">
                            <p className="small-top-heading">What our users say</p>
                            <h2 className="main-big-heading">Testimonials</h2>
                            <Button href="/get-started/"
                                    className="new-blue-link" color="primary">
                                Get Started <ArrowForwardThinIcon/>
                            </Button>
                        </div>

                        <div className="testi-list-section">
                            <div className="testi-list">
                                {
                                    testimonials && testimonials.map((testimonials) => (
                                        <div className={`single-testimonial-item  ${testimonials.backgroundColorCode}`} >
                                            <h5 className="testi-item-heading">{testimonials.name}</h5>
                                            <CommaIcons className="testi-item-icon"/>
                                            <p className="testi-item-para" dangerouslySetInnerHTML={{__html: testimonials.description}}></p>
                                        </div>
                                    ))
                                }
                            </div>

                        </div>
                    </div>
                </div>

                <div className="new-home-letter-one">
                    <div className="new-home-letter-one-inner">
                        <img className="letter-one-image person-cared-image"
                             src={abovefooter.image.file.url}

                             alt="home letter"/>
                        <h2 className="home-letter-one-heading">{abovefooter.heading}</h2>
                        <p className="home-letter-one-para" dangerouslySetInnerHTML={{__html: abovefooter.description.description}}></p>
                        <Button href={`/${abovefooter.link}/`}
                                className="new-blue-link" color="primary">
                            {abovefooter.linkText} <ArrowForwardThinIcon/>
                        </Button>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default CommunityPayment

export const query = graphql`
query getCommunityPaymentModelData {
   allContentfulCommunityPaymentModel {
    edges {
      node {
        topsection {
          title
          description {
            description
          }
          imageOrVideo {
            title
            description
            file {
              url
              fileName
              contentType
            }
          }
        }
        belowTopSection {
          title
          description {
            description
          }
        }
        shortsections {
          heading
          description {
            description
          }
          image {
            title
            description
            file {
              url
              fileName
              contentType
            }
          }
          alignment
          linkText
          link
        }
        testimonials {
          name
          description
          backgroundColorCode
        }
        abovefooter {
          heading
          description {
            description
          }
          image {
            title
            description
            file {
              url
              fileName
              contentType
            }
          }
          alignment
          linkText
          link
        }
      }
    }
  }
}


`;
